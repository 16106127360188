import React, { useState, useEffect } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import _ from 'lodash';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { FlexContainer, Heading, LinkRow, SystemsCell, SystemsContainer } from '../components/Notes/LinkRow';
import { slugToTitle } from '../../utils/slug';
import { sortNodesBySystem, sortNodesByYear, sortNodesIndex } from '../utility/sort';
import Subheader from '../components/Subheader';
import { getIconSrc } from '../utility/icons';
import { getTheme } from '../utility/theme';
import { generateSection, StickyTop } from '../templates/directory';
import { Abbrev, getSystemsAbbreviation } from '../components/Notes/RowReveal';
import { Box, Button } from '@material-ui/core';

const Row = styled.div`
  margin-bottom: 3rem;
`;

const Settings = props => {
  return (
    <Layout>
      <SEO title="Settings" />
      <Subheader crumbs={props.pageContext.crumbs} />

      <h1>Settings</h1>
      <Row>
          <p>Last deployed:</p>
          <p>
            <i>{props.pageContext.lastDeployed}</i>
          </p>
        </Row>
    </Layout>
  )
}

export default Settings;
